<template>
  <div class="aw-empty">
    <img
      src="@/assets/images/achievements/empty/awards.svg"
      alt="award"
      width="64"
      height="64"
    />
    <div class="aw-empty__content">
      <h6>{{ $t("my_achievements.empty.card_title") }}</h6>
      <p>{{ $t("my_achievements.empty.card_subtitle") }}</p>
    </div>
    <div class="aw-empty__name">
      <div class="aw-empty__name--left">
        <h6>{{ $t("my_achievements.empty.name_confirmation") }}</h6>
        <p>
          {{ $t("my_achievements.empty.name_description") }}
        </p>
      </div>
      <LxpButton variant="accent" @click="showModal = true">{{
        $t("general.confirm")
      }}</LxpButton>
    </div>
    <LxpButton variant="outline-primary" @click="navigateToDiscovery">
      {{ $t("my_achievements.empty.button") }}
    </LxpButton>
    <ConfirmName v-if="showModal" @close="showModal = false" />
  </div>
</template>
<script>
import { LxpButton } from "didactica";
// components
import ConfirmName from "./Shared/ConfirmName.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LxpButton,
    ConfirmName
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    ...mapGetters(["allProfile"])
  },
  mounted() {
    if (Object.keys(this.allProfile).length === 0) {
      this.$store.dispatch("getProfile");
    }
  },
  methods: {
    navigateToDiscovery() {
      this.$router.push({ name: "course-discovery" });
    }
  }
};
</script>
<style lang="scss" scoped>
.aw-empty {
  @include center;
  gap: 24px 0;
  height: 100%;
  .aw-empty__content {
    h6 {
      @include h6;
      color: $brand-neutral-900;
      margin-bottom: 0;
    }
    p {
      @include body-regular($brand-neutral-700, 500);
    }
  }
  .aw-empty__name {
    @include horizontal-space-between;
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    background: $brand-primary-100;
    .aw-empty__name--left {
      text-align: left;
      h6 {
        @include subtitle-regular;
        color: $brand-neutral-900;
        margin-bottom: 0;
      }
      p {
        @include subtitle-small($brand-neutral-700, 400);
        margin-bottom: 0;
      }
    }
  }
}
</style>
